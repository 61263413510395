<template>
  <moe-page>
    <moe-card class="mb-20" :autoHeight="true">
      <template slot="header">
        <div class="df aic jcsb">
          <div class="df fdc">
            <div class="font-20 fwb">商品前台分类</div>
            <div class="font-14">可以根据运营需求在APP展示商品分类信息，点击三级分类可跳转绑定商品列表</div>
          </div>
          <div>
            <el-button type="primary" icon="el-icon-circle-plus-outline" @click="$moe_coordinator.navigateTo('/contentManage/frontCategory/add')">新增分类</el-button>
          </div>
        </div>
      </template>

      <moe-table ref="categoryListTreeTable" url="/shop/frontCategory/all" :params="frontcategoryParmas" :numberShow="false" rowKey="id">
        <el-table-column prop="name" label="分类名称" min-width="130" :show-overflow-tooltip="true" />
        <el-table-column label="分类级别">
          <template slot-scope="{ row }">
            {{ $moe_format.getCategoryLevel(row.level) }}
          </template>
        </el-table-column>
        <el-table-column prop="sort" label="排序" width="80" />
        <el-table-column prop="empty" label="分类图片" width="130">
          <template slot-scope="{ row }">
            <moe-image v-if="row.coverUrl" :src="row.coverUrl" width="70px" height="70px" />
          </template>
        </el-table-column>
        <el-table-column prop="goodsNum" label="关联商品数" width="120" />
        <el-table-column label="状态" width="90">
          <template slot-scope="{ row }">
            <div v-if="row.status" @click="handleShelf(row, false, '禁用')">
              <moe-tag type="success"><i class="el-icon-unlock" />启用</moe-tag>
            </div>
            <div v-else @click="handleShelf(row, true, '启用')">
              <moe-tag type="danger"><i class="el-icon-lock" />禁用</moe-tag>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="创建时间" min-width="150">
          <template slot-scope="{ row }">
            {{ $moe_time.getTimeStamp(row.createTime) }}
          </template>
        </el-table-column>
        <el-table-column label="操作" width="250" fixed="right">
          <div class="moe-table__btns" slot-scope="{ row }">
            <el-button type="warning" size="mini" icon="el-icon-edit" @click="$moe_coordinator.navigateTo(`/contentManage/frontCategory/add`, { id: row.id })">编辑</el-button>
            <el-button type="danger" size="mini" icon="el-icon-delete" @click="handleDelete(row)">删除</el-button>
          </div>
        </el-table-column>
      </moe-table>
    </moe-card>
  </moe-page>
</template>

<script>
export default {
  name: 'ContentManageFrontCategoryList',
  data() {
    return {
      frontcategoryParmas: {
        pageNum: 1
      }
    }
  },
  methods: {
    handleShelf({ id }, status, text) {
      this.$moe_layer.confirm(`您确定要"${text}"当前选项吗？`, () => {
        this.$moe_api.CONTENT.updateFrontCategoryStatus({ id, status }).then((data) => {
          if (data.code === 200) {
            this.$moe_msg.success('操作成功');
            this.$refs['categoryListTreeTable'].searchData();
          } else {
            this.$moe_msg.error(data.message);
          }
        })
      })
    },
    handleDelete({ id, name }) {
      this.$moe_layer.confirm(`您确定要删除当前选项"${name}"吗？`, () => {
        this.$moe_api.CONTENT.deleteFrontCategory({ id }).then((data) => {
          if (data.code == 200) {
            this.$moe_msg.success('删除成功');
            this.$refs['categoryListTreeTable'].searchData();
          } else {
            this.$moe_msg.error(data.message);
          }
        })
      });
    }
  }
}
</script>

<style lang="scss">

</style>